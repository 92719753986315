'use client'

import React, { createContext, useContext, useEffect, useState } from 'react'

interface ScreenContextType {
  isMobile: boolean
}

const ScreenContext = createContext<ScreenContextType>({ isMobile: false })

export function ScreenContextProvider({ children }: { children: React.ReactNode }) {
  const [isMobile, setIsMobile] = useState(false)

  const checkScreenSize = () => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 640)
    }
  }

  useEffect(() => {
    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)
    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return (
    <ScreenContext.Provider value={{ isMobile }}>
      {children}
    </ScreenContext.Provider>
  )
}

// 创建一个自定义 hook 来使用这个 context
export const useScreen = () => {
  const context = useContext(ScreenContext)
  if (context === undefined) {
    throw new Error('useScreen must be used within a ScreenContextProvider')
  }
  return context
} 