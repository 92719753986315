const translation = {
  分析: 'محادثة',
  详情: 'التفاصيل',
  复制成功: 'تم النسخ بنجاح',
  所属经理: 'المدير',
  投资策略: 'استراتيجية الاستثمار',
  目标: 'الهدف',
  状态: 'الحالة',
  建立日期: 'تاريخ الإنشاء',
  最近分析: 'تاريخ آخر تحليل',
  风险概况: 'ملف المخاطر',
  选择客户: 'اختيار العميل',
  刚刚: 'الآن',
  save: 'نعم',
  cancel: 'إلغاء',
  viewAll: 'عرض الكل',
  moreLikeThis: 'المزيد مثل هذا',
  userSearch: {
    placeholder: 'البحث باسم العميل',
    search: 'بحث',
    sortBy: 'ترتيب حسب',
    sortOrder: 'ترتيب',
    asc: 'تصاعدي',
    desc: 'تنازلي',
    reset: 'إعادة تعيين',
    apply: 'تطبيق',
    isProfit: 'مربح',
    all: 'الكل',
    low: 'منخفض',
    medium: 'متوسط',
    high: 'مرتفع',
    riskTolerance: 'تحمل المخاطر',
  },
  chat: {
    思考中: 'جاري التفكير',
    思考秒: 'جاري التفكير لمدة {{number}} ثانية',
    推荐问: '\nأسئلة مقترحة:',
    消息异常: 'خطأ في الرسالة',
    conversations: 'المحادثات',
    newConversation: 'محادثة جديدة',
    deleteTip: 'تلميح الحذف',
    confirmDeletion: 'تأكيد الحذف؟',
    exportToPdf: 'تصدير إلى PDF',
    sendToEmail: 'إرسال إلى البريد الإلكتروني',
    exportTip: 'تلميح التصدير',
    contentTooLong: "المحتوى طويل جداً. يرجى نسخ المحتوى يدوياً.",
    expandSession: 'توسيع قائمة الجلسات',
  },
  index: {
    title: 'نسبة ربح وخسارة العملاء',
    亏损客户数: 'الحسابات الخاسرة',
    盈利客户数: 'الحسابات الرابحة',
    全部: 'الكل',
    亏损: 'خسارة',
    盈利: 'ربح',
  },
  menus: {
    首页: 'الرئيسية',
    文档: 'البيانات',
    客户: 'الحسابات',
    指导: 'دليل المستخدم',
    定价: 'الاشتراكات',
    计划: 'الخطط',
  },
  login: {
    wealthManager: 'مدير الثروات',
    individual: 'مستثمر فردي',
    选择角色: 'الرجاء اختيار دورك',
    genieTitle: 'مساعدك الشخصي في إدارة الثروات',
    登录描述: 'مرحباً بك في مساعدنا المالي - اختبر التمويل الذكي معنا!',
    请输入用户名: 'الرجاء إدخال اسم المستخدم',
    请输入邮箱: 'الرجاء إدخال البريد الإلكتروني',
    获取验证码: 'الحصول على الرمز',
    请输入邮箱验证码: 'أدخل رمز التحقق',
    请确认密码: 'تأكيد كلمة المرور',
    FirstName: 'الاسم الأول',
    LastName: 'اسم العائلة',
    请输入手机号: 'أدخل رقم الهاتف',
    输入邮箱: 'أدخل بريدك الإلكتروني',
    输入密码: 'أدخل كلمة المرور',
    请输入密码: 'الرجاء إدخال كلمة المرور',
    账号密码不正确: 'البريد الإلكتروني أو كلمة المرور غير صحيحة',
    注册账号: 'تسجيل',
    登录: 'تسجيل الدخول',
    注册: 'تسجيل',
    不能为空: 'مطلوب',
    密码规则: "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وتشمل أرقاماً وحروفاً ورموزاً خاصة",
    两次输入的密码不一致: "كلمتا المرور غير متطابقتين",
    邮箱格式不正确: "صيغة البريد الإلكتروني غير صحيحة",
    注册失败: "فشل التسجيل",
    注册成功: "تم التسجيل بنجاح",
    发送验证码失败: "حدث خطأ أثناء إرسال رمز التحقق، يرجى المحاولة لاحقاً",
    注册失败1: "يجب أن يتكون اسم المستخدم من 3 أحرف على الأقل",
    注册失败2: "البريد الإلكتروني موجود مسبقاً",
    注册失败3: "اسم المستخدم موجود مسبقاً",
    注册失败4: "رقم الهاتف موجود مسبقاً",
    注册失败5: "يجب أن يكون رقم الهاتف أرقاماً",
    注册失败6: "الرمز غير صحيح",
    logout: 'تسجيل الخروج',
    个人信息: 'تعديل الملف الشخصي',
    公司: 'الشركة',
    邮箱: 'البريد الإلكتروني',
  },
  userDetailTable: {
    title: 'سجل معاملات المحفظة',
    downTip: 'تحميل سجل المعاملات',
    投资类别: 'فئة الأصول',
    特定投资或证券的名称: 'الاسم',
    公开交易证券的股票代码或符号: 'الرمز',
    客户持有的股份或单位数量: 'الكمية المملوكة',
    最初购买时的价格: 'تكلفة الشراء',
    当前市场价格: 'السعر الحالي',
    总价值: 'القيمة السوقية',
    原始成本: 'أساس التكلفة',
    市场价值与成本基础之间的差额: 'الربح/الخسارة غير المحققة',
    年收益率: 'العائد السنوي',
    投资收入: 'الدخل',
    总资产分配给此资产的比例: 'التخصيص',
    风险水平: 'مستوى المخاطر',
    更新投资组合数据的日期: 'تاريخ الشراء',
  },
  upload: {
    文件类型不支持: 'نوع الملف غير مدعوم',
    文件太大: 'الملف كبير جداً، يجب ألا يتجاوز {{size}} ميجابايت',
    上传成功: 'تم الرفع بنجاح',
    上传限制个数: 'حد الرفع الجماعي {{number}} ملفات',
    拖拽文件至此或者: 'اسحب الملفات هنا، أو',
    下载模板: 'تحميل القالب',
    下载用户数据: 'تحميل بيانات حسابات العملاء من Genie',
    下载理财数据: 'تحميل بيانات سجل معاملات المحفظة من Genie',
    上传用户或理财信息: 'رفع بيانات الحساب أو الثروة',
    上传理财信息: 'رفع المعلومات المالية',
    选择文件: 'اختيار الملفات',
    支持文件: 'يدعم {{exp}}، كل ملف لا يتجاوز {{size}} ميجابايت',
    importAccountData: 'استيراد بيانات الحساب إلى Genie',
    importPortfolioTransaction: 'استيراد معاملات المحفظة لحساب العميل إلى Genie',
    importTemplate: 'استيراد بيانات حساب العميل إلى Genie',
    importPortfolioTemplate: 'استيراد بيانات سجل معاملات المحفظة إلى Genie',
  },
  api: {
    success: 'نجاح',
    actionSuccess: 'تم الإجراء بنجاح',
    saved: 'تم الحفظ',
    create: 'تم الإنشاء',
    remove: 'تم الحذف',
    deleFail: 'فشل الحذف',
  },
  price: {
    free: 'مجاني',
    title: 'Genie',
    subtitle: 'مساعد إدارة الثروات المدعوم بالذكاء الاصطناعي',
    perMonth: 'شهرياً',
    includes: 'يشمل',
    plusPlan: 'الخطة الإضافية',
    mostPopular: 'الأكثر شعبية',
    proPlan: 'الخطة الاحترافية',
    paymentMethods: 'Stripe و PayPal',
    description: {
      free: "مثالي للمستثمرين الأفراد المبتدئين، يوفر ميزات أساسية لتحليل وإدارة المحفظة لمساعدتك في بدء رحلة الاستثمار الذكي.",
      plus: "مصمم للمستثمرين المتقدمين، يوفر تحليلات استثمارية أعمق وتوصيات استراتيجية متنوعة لمساعدتك في تحقيق عوائد استثمارية أفضل.",
      pro: "مصمم للمستثمرين المحترفين، يقدم حلولاً شاملة لإدارة الاستثمار، بما في ذلك أدوات تحليلية متقدمة وخدمات استشارية مخصصة."
    },
    features: {
      analysis: 'تحليل المحفظة والتقارير',
      optimization: 'تحسين الاستثمار\nالمخصص والتوصيات',
      trends: 'رؤى اتجاهات السوق',
      decisions: 'اتخاذ قرارات شفافة',
      strategy: 'استراتيجية استثمار موجهة نحو الأهداف',
      monitoring: 'مراقبة أداء المحفظة',
      simulation: 'محاكاة السيناريوهات',
      tax: 'تحسين الكفاءة الضريبية',
      portfolios2: 'حتى محفظتين',
      portfolios10: 'حتى 10 محافظ',
      portfolios50: 'حتى 50 محفظة',
      trial: 'تجربة مجانية لمدة شهر للمستخدمين الجدد',
      freePlan: '+ جميع ميزات الخطة المجانية'
    },
    extraCredits: {
      title: 'رصيد إضافي',
      description: 'متاح في جميع المستويات',
      price: '20 دولار',
      unit: 'لكل 10,000 رصيد'
    },
    extraStorage: {
      title: 'تخزين معرفي إضافي',
      description: 'متاح في جميع المستويات',
      price: '100 دولار',
      unit: 'لكل جيجابايت'
    }
  },
  overview: {
    name: 'اسم العميل',
    title: 'نظرة عامة',
    thisWeek: 'هذا الأسبوع',
    thisMonth: 'هذا الشهر',
    thisYear: 'هذا العام',
    totalAccounts: 'إجمالي الحسابات',
    totalAssets: 'أصول إجمالي الحسابات',
    accounts: 'لوحة تحكم حسابي',
    account: 'الحساب',
    expenseCategory: 'توزيع المحفظة',
    risk: 'المخاطر',
    balance: 'الرصيد',
    unrealizedProfits: 'الأرباح غير المحققة',
    objective: 'الهدف',
    investmentStrategies: 'استراتيجيات الاستثمار',
    actions: 'الإجراءات',
    portfolioTrend: 'اتجاه المحفظة',
    balanceTrend: 'اتجاه الرصيد',
  },
  settings: {
    title: 'الإعدادات',
    Basics: 'الأساسيات',
    profileInformation: 'معلومات الملف الشخصي',
    uploadNewPicture: 'رفع صورة جديدة',
    portfolioObjective: "هدف المحفظة",
    risk: 'المخاطر',
    investmentStrategies: 'استراتيجيات الاستثمار',
    customizedInvestmentStrategies: 'استراتيجيات استثمار مخصصة'
  },
  plan: {
    planList: 'قائمة الخطط',
    addPlan: 'إضافة خطة',
    planName: 'اسم الخطة',
    sendEmail: 'البريد الإلكتروني للإرسال',
    planTime: 'الجدول الزمني',
    details: 'التفاصيل',
    enterPlanName: 'الرجاء إدخال اسم الخطة',
    selectEmail: 'اختيار البريد الإلكتروني',
    selectedEmail: 'البريد الإلكتروني المحدد',
    emailHeader: 'ترويسة البريد الإلكتروني',
    enterEmailHeader: 'الرجاء إدخال ترويسة البريد الإلكتروني',
    emailFooter: 'تذييل البريد الإلكتروني',
    enterEmailFooter: 'الرجاء إدخال تذييل البريد الإلكتروني',
    emailContent: 'محتوى البريد الإلكتروني',
    selectEmailContent: 'الرجاء اختيار محتوى البريد الإلكتروني',
    selectReceiverGroup: 'اختيار مجموعة المستلمين',
    selectedReceiverGroup: 'مجموعة المستلمين المحددة',
    nameRequired: 'الرجاء إدخال اسم الخطة',
    emailRequired: 'الرجاء اختيار البريد الإلكتروني',
    contentRequired: 'الرجاء اختيار محتوى البريد الإلكتروني',
    receiverRequired: 'الرجاء اختيار مجموعة المستلمين',
    scheduleRequired: 'الرجاء تحديد موعد الإرسال',
    scheduleTime: 'موعد الإرسال المجدول',
    daily: 'يومياً',
    weekly: 'أسبوعياً',
    monthly: 'شهرياً',
    time: 'الوقت',
    selectWeekday: 'اختر يوم الأسبوع',
    selectDate: 'اختر التاريخ',
    monday: 'الإثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الأربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت',
    sunday: 'الأحد',
    dayOfMonth: 'اليوم {{day}}',
    emailConfig: {
      title: 'تكوين البريد الإلكتروني',
      protocol: 'اختر البروتوكول',
      emailAddress: 'عنوان البريد الإلكتروني',
      emailAddressPlaceholder: 'الرجاء إدخال عنوان البريد الإلكتروني',
      password: 'كلمة المرور',
      passwordPlaceholder: 'الرجاء إدخال كلمة المرور',
      mailServer: 'خادم البريد',
      mailServerPlaceholder: 'مثال: imap.gmail.com',
      port: 'منفذ خادم البريد',
      portPlaceholder: 'مثال: 993',
      cancel: 'إلغاء',
      confirm: 'تأكيد'
    },
    receiverGroup: {
      configTitle: 'تكوين مجموعة المستلمين الجديدة',
      groupName: 'اسم المجموعة',
      groupNamePlaceholder: 'الرجاء إدخال اسم المجموعة',
      selectReceiver: 'اختيار المستلمين',
      selectReceiverPlaceholder: 'اختر المستلمين',
      description: 'وصف المجموعة',
      descriptionPlaceholder: 'الرجاء إدخال وصف المجموعة',
      nameRequired: 'الرجاء إدخال اسم المجموعة',
      selectRequired: 'الرجاء اختيار مستلم واحد على الأقل'
    },
    configureNewEmail: 'تكوين بريد إلكتروني جديد',
    edit: 'تعديل',
    cancel: 'إلغاء',
    confirm: 'تأكيد',
    emailUpdateSuccess: 'تم تحديث تكوين البريد الإلكتروني بنجاح!',
    emailCreateSuccess: 'تم إنشاء تكوين البريد الإلكتروني الجديد بنجاح!',
    unknownError: 'حدث خطأ غير معروف، يرجى المحاولة مرة أخرى.',
    receiver_group: {
      select_title: 'اختيار مجموعة المستلمين',
      config_new: 'تكوين مجموعة مستلمين جديدة',
      cancel: 'إلغاء',
      confirm: 'تأكيد',
      create_success: 'تم إنشاء مجموعة المستلمين الجديدة بنجاح!',
      create_error: 'فشل في إنشاء مجموعة المستلمين!'
    },
    searchProducts: 'البحث عن المنتجات',
    sortBy: 'ترتيب حسب',
    featured: 'مميز',
    popular: 'شائع',
    new: 'جديد',
    showing: 'عرض',
    filters: {
      allproducts: 'جميع المنتجات',
      uikit: 'مجموعة واجهة المستخدم',
      illustration: 'الرسوم التوضيحية',
      wireframekit: 'مجموعة الهيكل السلكي',
      icons: 'الأيقونات'
    },
    lifetime: 'مدى الحياة',
    reset: 'إعادة تعيين',
    apply: 'تطبيق',
    editContent: 'تحرير المحتوى',
    enterEmailTitle: 'الرجاء إدخال عنوان البريد الإلكتروني',
    save: 'حفظ',
    sendEmailNow: 'إرسال البريد الإلكتروني الآن',
    confirmSendEmail: 'هل أنت متأكد من إرسال البريد الإلكتروني الآن؟',
    sendSuccess: 'تم الإرسال بنجاح',
    sendFailed: 'فشل الإرسال',
    updateSuccess: 'تم التحديث بنجاح',
    updateFailed: 'فشل التحديث',
    client: 'العميل',
    sendTime: 'وقت الإرسال',
    emailTitle: 'عنوان البريد الإلكتروني',
    sendStatus: 'حالة الإرسال',
    sent: 'تم الإرسال',
    unsent: 'لم يتم الإرسال',
    operations: 'العمليات',
    table: {
      user: 'المستخدم',
      sendTime: 'وقت الإرسال',
      emailTitle: 'عنوان البريد الإلكتروني',
      emailContent: 'محتوى البريد الإلكتروني',
      status: 'الحالة',
      action: 'الإجراء'
    }
  },
  user: {
    noSimilarCustomers: 'لا يوجد عملاء مماثلون',
    similarity: 'درجة التشابه',
    similarInvestmentCustomers: 'حسابات مماثلة',
    aiAnalysis: 'تحليل AI، قد يستغرق بعض الوقت',
    customerComparison: 'مقارنة العملاء '
  },
  error: {
    compareClientFailed: 'فشل مقارنة العملاء'
  }
}

export default translation