'use client'
import React, { createContext, useContext, useState, ReactNode, useRef } from 'react';
import { Button } from '@nextui-org/react';

interface PopupContextType {
  isOpen: boolean;
  openPopup: (obj: { title: string; content: string; onSubmit?: () => void; showCancelBtn?: boolean }) => void;
  closePopup: () => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const usePopup = (): PopupContextType => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};

interface PopupProviderProps {
  children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [showCancelBtn, setShowCancelBtn] = useState<boolean>(false);
  const onSubmit = useRef(null)

  const openPopup = (obj: {title: string, content: string, onSubmit?: () => void, showCancelBtn?: boolean}) => {
    setTitle(obj.title);
    setContent(obj.content);
    setIsOpen(true);
    obj.onSubmit && (onSubmit.current = obj.onSubmit);
    setShowCancelBtn(obj.showCancelBtn || false);
  };
  const closePopup = () => {
    if (onSubmit.current) {
      onSubmit.current()
    }
    onSubmit.current = null
    setIsOpen(false)
  };

  return (
    <PopupContext.Provider value={{ isOpen, openPopup, closePopup }}>
      {children}
      {isOpen && <Popup closePopup={closePopup} title={title} content={content} showCancelBtn={showCancelBtn} setIsOpen={setIsOpen} />}
    </PopupContext.Provider>
  );
};

interface PopupProps {
  closePopup: () => void;
  title: string;
  content: string;
  showCancelBtn: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Popup: React.FC<PopupProps> = ({ closePopup, title, content, showCancelBtn, setIsOpen }) => {
  return (
    <div className="relative z-10" id="headlessui-dialog-:r18:" role="dialog" aria-modal="true" data-headlessui-state="open" aria-labelledby="headlessui-dialog-title-:r1a:">
      <div className="fixed inset-0 transition-opacity" style={{backgroundColor: 'rgba(107, 114, 128, 0.7)'}}></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[400px] sm:p-6"
            id="headlessui-dialog-panel-:r19:"
            data-headlessui-state="open"
            style={{width: '400px'}}
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-6 w-6 text-yellow-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="headlessui-dialog-title-:r1a:" data-headlessui-state="open">
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{content}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <Button
                type="button"
                color='primary'
                className='w-full mt-4'
                  size='sm'
                onClick={closePopup}
              >
                确定
              </Button>
              {showCancelBtn && (
                <Button
                type="button"
                  size='sm'
                className='w-full mt-2'
                onClick={() => setIsOpen(false)}
              >
                取消
              </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
