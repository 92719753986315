import React, { createContext, useContext, useRef, useState } from 'react';
import Modal from '@/components/Modal';

type ModalContextType = {
  openModal: (title: string, content: string, hiddenCancelButton: boolean) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const modalRef = useRef<any>(null);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [hiddenCancelButton, setHiddenCancelButton] = useState<boolean>(true);

  const openModal = (title: string, content: string, hiddenCancelButton: boolean) => {
    setModalTitle(title);
    setModalContent(content);
    setHiddenCancelButton(hiddenCancelButton);
    modalRef.current?.onOpen();
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      {children}
      <Modal
        ref={modalRef}
        className=''
        title={modalTitle}
        content={modalContent}
        hiddenCancelButton={hiddenCancelButton}
        onSubmit={() => {}}
      >
        <div></div>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}; 