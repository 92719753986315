
export const formatCurrency = function (amount: number | string, dot = 2) {
  amount = amount + ''
  // 确保输入为数字，并四舍五入到两位小数
  const roundedAmount = parseFloat(amount).toFixed(dot);

  // 使用正则表达式添加千分位分隔符
  const formattedAmount = roundedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedAmount;
}
export function randomString(len) {
  len = len || 32
  const $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const maxPos = $chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    // 0~32的整数
    pwd += $chars.charAt(Math.floor(Math.random() * (maxPos + 1)))
  }
  return pwd
}

export const formatDate = (tmp: string | number | Date, format) => {
  let fmt = format || 'yyyy-MM-dd hh:mm:ss'
  let date = null
  if (!tmp) {
    return ''
  }
  if (tmp instanceof Date) {
    date = tmp
  } else if (typeof tmp === 'string' || typeof tmp === 'number') {
    date = new Date(tmp)
  } else {
    return ''
  }
  if (date === 'Invalid Date') {
    const aaa = (tmp as string).replace(/-/g, '/')
    date = new Date(aaa)
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (`${date.getFullYear()}`).substr(4 - RegExp.$1.length),
    )
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : (`00${o[k]}`).substr((`${o[k]}`).length),
      )
    }
  })
  return fmt
}

async function decodeBase64AndDecompress(base64String: string) {
  const binaryString = atob(base64String)
  const compressedUint8Array = Uint8Array.from(binaryString, char => char.charCodeAt(0))
  const decompressedStream = new Response(compressedUint8Array).body.pipeThrough(new DecompressionStream('gzip'))
  const decompressedArrayBuffer = await new Response(decompressedStream).arrayBuffer()
  return new TextDecoder().decode(decompressedArrayBuffer)
}

export function getProcessedInputsFromUrlParams(): Record<string, any> {
  console.log('21234', window.location.search);
  const urlParams = new URLSearchParams(window.location.search)
  const inputs: Record<string, any> = {}
  urlParams.forEach((value, key) => {
    inputs[key] = decodeURIComponent(value)
  })
  return inputs
}


export const numberWithCommas = (x: any, decimalNum: number = 2) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts[1] && decimalNum) {
    parts[1] = parts[1].slice(0, decimalNum);
  }
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export function parseCronExpression(cronExpression) {
  try {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = cronExpression.split(' ');

    // 处理特殊字符
    const parseField = (field, type) => {
      if (field === '*') return null;
      if (field.includes('/')) {
        const [, interval] = field.split('/');
        return `每${interval}${type}`;
      }
      if (field.includes(',')) {
        return field.split(',').join('、');
      }
      if (field.includes('-')) {
        const [start, end] = field.split('-');
        return `${start}至${end}`;
      }
      return field;
    };

    // 解析时间
    const parseTime = () => {
      const parsedMinute = parseField(minute, '分钟');
      const parsedHour = parseField(hour, '小时');

      if (parsedHour === null && parsedMinute === null) return '每分钟';
      if (parsedHour === null) return parsedMinute ? `每小时的第${parsedMinute}分` : '每分钟';
      if (parsedMinute === null) return `每天${parsedHour}点的每分钟`;

      return `${parsedHour}点${parsedMinute}分`;
    };

    // 解析星期
    const parseWeekday = (day) => {
      const weekdays = {
        '0': '周日',
        '1': '周一',
        '2': '周二',
        '3': '周三',
        '4': '周四',
        '5': '周五',
        '6': '周六',
        '7': '周日',
        'MON': '周一',
        'TUE': '周二',
        'WED': '周三',
        'THU': '周四',
        'FRI': '周五',
        'SAT': '周六',
        'SUN': '周日'
      };
      return weekdays[day] || day;
    };

    // 解析月份
    const parseMonth = (mon) => {
      const months = {
        '1': '1月', '2': '2月', '3': '3月', '4': '4月',
        '5': '5月', '6': '6月', '7': '7月', '8': '8月',
        '9': '9月', '10': '10月', '11': '11月', '12': '12月',
        'JAN': '1月', 'FEB': '2月', 'MAR': '3月', 'APR': '4月',
        'MAY': '5月', 'JUN': '6月', 'JUL': '7月', 'AUG': '8月',
        'SEP': '9月', 'OCT': '10月', 'NOV': '11月', 'DEC': '12月'
      };
      return months[mon] || mon;
    };

    let schedule = '';

    // 构建完整的时间表达式
    const timeStr = parseTime();

    // 处理月份
    const monthStr = parseField(month, '月');
    if (monthStr) {
      schedule += monthStr.split('、').map(m => parseMonth(m)).join('、');
    }

    // 处理日期
    const dayStr = parseField(dayOfMonth, '天');
    if (dayStr && dayStr !== '*') {
      schedule += (schedule ? '' : '每月') + `${dayStr}日`;
    }

    // 处理星期
    const weekdayStr = parseField(dayOfWeek, '天');
    if (weekdayStr && weekdayStr !== '*') {
      schedule += (schedule ? '' : '每') +
        weekdayStr.split('、').map(d => parseWeekday(d)).join('、');
    }

    // 如果没有特定的日期/星期限制
    if (!schedule) {
      schedule = '每天';
    }

    return `${schedule} ${timeStr}`;

  } catch (error) {
    console.error('Cron expression parsing error:', error);
    return cronExpression; // 发生错误时返回原始表达式
  }
} 