const translation = {
  分析: 'チャット',
  详情: '詳細',
  复制成功: 'コピーしました',
  所属经理: 'マネージャー',
  投资策略: '投資戦略',
  目标: '目標',
  状态: 'ステータス',
  建立日期: '作成日',
  最近分析: '最新分析日',
  风险概况: 'リスクプロファイル',
  选择客户: '顧客を選択',
  刚刚: 'たった今',
  save: 'はい',
  cancel: 'キャンセル',
  viewAll: 'すべて表示',
  moreLikeThis: '類似項目',
  userSearch: {
    placeholder: '顧客名で検索',
    search: '検索',
    sortBy: '並び替え',
    sortOrder: '並び順',
    asc: '昇順',
    desc: '降順',
    reset: 'リセット',
    apply: '適用',
    isProfit: '収益あり',
    all: 'すべて',
    low: '低',
    medium: '中',
    high: '高',
    riskTolerance: 'リスク許容度',
  },
  chat: {
    思考中: '考え中',
    思考秒: '{{number}}秒間考え中',
    推荐问: '\nおすすめの質問：',
    消息异常: 'メッセージエラー',
    conversations: '会話',
    newConversation: '新規会話',
    deleteTip: '削除のヒント',
    confirmDeletion: '削除を確認しますか？',
    exportToPdf: 'PDFにエクスポート',
    sendToEmail: 'メールで送信',
    exportTip: 'エクスポートのヒント',
    contentTooLong: "内容が長すぎます。手動でコピーしてください。",
    expandSession: 'セッションリストを展開',
  },
  index: {
    title: '顧客損益比率',
    亏损客户数: '損失アカウント',
    盈利客户数: '収益アカウント',
    全部: 'すべて',
    亏损: '損失',
    盈利: '収益',
  },
  menus: {
    首页: 'ホーム',
    文档: 'データ',
    客户: 'アカウント',
    指导: 'ユーザーガイド',
    定价: 'サブスクリプション',
    计划: '計画',
  },
  login: {
    wealthManager: 'ウェルスマネージャー',
    individual: '個人投資家',
    选择角色: 'あなたの役割を選択してください',
    genieTitle: 'あなたの資産運用マネージャーコパイロット',
    登录描述: '金融アシスタントへようこそ - スマートな金融を体験しましょう！',
    请输入用户名: 'ユーザー名を入力してください',
    请输入邮箱: 'メールアドレスを入力してください',
    获取验证码: 'コードを取得',
    请输入邮箱验证码: 'メール認証コードを入力してください',
    请确认密码: 'パスワードを確認してください',
    FirstName: '名',
    LastName: '姓',
    请输入手机号: '電話番号を入力してください',
    输入邮箱: 'メールアドレスを入力',
    输入密码: 'パスワードを入力',
    请输入密码: 'パスワードを入力してください',
    账号密码不正确: 'メールアドレスまたはパスワードが正しくありません',
    注册账号: '登録',
    登录: 'ログイン',
    注册: '登録',
    不能为空: '必須項目です',
    密码规则: "パスワードは8文字以上で、数字、文字、特殊文字を含める必要があります",
    两次输入的密码不一致: "入力されたパスワードが一致しません",
    邮箱格式不正确: "メールアドレスの形式が正しくありません",
    注册失败: "登録に失敗しました",
    注册成功: "登録が完了しました",
    发送验证码失败: "認証コードの送信に失敗しました。後でもう一度お試しください",
    注册失败1: "ユーザー名は3文字以上である必要があります",
    注册失败2: "このメールアドレスは既に存在します",
    注册失败3: "このユーザー名は既に存在します",
    注册失败4: "この電話番号は既に存在します",
    注册失败5: "電話番号は数字である必要があります",
    注册失败6: "コードが間違っています",
    logout: 'ログアウト',
    个人信息: 'プロフィール編集',
    公司: '会社',
    邮箱: 'メールアドレス',
  },
  userDetailTable: {
    title: 'ポートフォリオ取引履歴',
    downTip: '取引履歴をダウンロード',
    投资类别: '資産クラス',
    特定投资或证券的名称: '名称',
    公开交易证券的股票代码或符号: 'シンボル',
    客户持有的股份或单位数量: '保有数量',
    最初购买时的价格: '購入コスト',
    当前市场价格: '現在価格',
    总价值: '時価総額',
    原始成本: '取得原価',
    市场价值与成本基础之间的差额: '未実現損益',
    年收益率: '年間利回り',
    投资收入: '投資収益',
    总资产分配给此资产的比例: '配分',
    风险水平: 'リスクレベル',
    更新投资组合数据的日期: '購入日',
  },
  upload: {
    文件类型不支持: 'ファイルタイプがサポートされていません',
    文件太大: 'ファイルが大きすぎます（{{size}}MB以下にしてください）',
    上传成功: 'アップロード成功',
    上传限制个数: '一括アップロード制限：{{number}}ファイル',
    拖拽文件至此或者: 'ファイルをここにドラッグ、または',
    下载模板: 'テンプレートをダウンロード',
    下载用户数据: 'Genieから顧客アカウントデータをダウンロード',
    下载理财数据: 'Genieからポートフォリオ取引履歴データをダウンロード',
    上传用户或理财信息: 'アカウントまたは資産データをアップロード',
    上传理财信息: '財務情報をアップロード',
    选择文件: 'ファイルを選択',
    支持文件: '対応形式：{{exp}}、各ファイル{{size}}MB以下',
    importAccountData: 'アカウントデータをGenieにインポート',
    importPortfolioTransaction: '顧客アカウントのポートフォリオ取引をGenieにインポート',
    importTemplate: '顧客アカウントデータをGenieにインポート',
    importPortfolioTemplate: 'ポートフォリオ取引履歴データをGenieにインポート',
  },
  api: {
    success: '成功',
    actionSuccess: 'アクション成功',
    saved: '保存しました',
    create: '作成しました',
    remove: '削除しました',
    deleFail: '削除に失敗しました',
  },
  price: {
    free: '無料',
    title: 'Genie',
    subtitle: 'AI搭載資産運用マネージャーコパイロット',
    perMonth: '月額',
    includes: '含まれるもの',
    plusPlan: 'プラスプラン',
    mostPopular: '人気',
    proPlan: 'プロプラン',
    paymentMethods: 'StripeとPayPal',
    description: {
      free: "初めての個人投資家向けに、基本的なポートフォリオ分析と管理機能を提供し、スマートな投資の旅を始めるのに最適です。",
      plus: "上級投資家向けに設計され、より深い投資分析と多様な戦略提案を提供し、より良い投資リターンの達成をサポートします。",
      pro: "プロの投資家向けに、高度な分析ツールとパーソナライズされたアドバイザリーサービスを含む包括的な投資管理ソリューションを提供します。"
    },
    features: {
      analysis: 'ポートフォリオ分析とレポート',
      optimization: 'パーソナライズされた投資\n最適化と\n推奨',
      trends: '市場動向の洞察',
      decisions: '透明性のある意思決定',
      strategy: '目標指向の投資戦略',
      monitoring: 'ポートフォリオパフォーマンス監視',
      simulation: 'シナリオシミュレーション',
      tax: '税効率の最適化',
      portfolios2: '最大2ポートフォリオ',
      portfolios10: '最大10ポートフォリオ',
      portfolios50: '最大50ポートフォリオ',
      trial: '新規ユーザー1ヶ月無料トライアル',
      freePlan: '+ 無料プランのすべての機能'
    },
    extraCredits: {
      title: '追加クレジット',
      description: 'すべてのプランで利用可能',
      price: '2,000円',
      unit: '10,000クレジットあたり'
    },
    extraStorage: {
      title: '追加ナレッジストレージ',
      description: 'すべてのプランで利用可能',
      price: '10,000円',
      unit: 'GB あたり'
    }
  },
  overview: {
    name: '顧客名',
    title: '概要',
    thisWeek: '今週',
    thisMonth: '今月',
    thisYear: '今年',
    totalAccounts: '総アカウント数',
    totalAssets: '総アカウント資産',
    accounts: 'マイアカウントダッシュボード',
    account: 'アカウント',
    expenseCategory: 'ポートフォリオ配分',
    risk: 'リスク',
    balance: '残高',
    unrealizedProfits: '未実現利益',
    objective: '目標',
    investmentStrategies: '投資戦略',
    actions: 'アクション',
    portfolioTrend: 'ポートフォリオトレンド',
    balanceTrend: '残高トレンド',
  },
  settings: {
    title: '設定',
    Basics: '基本',
    profileInformation: 'プロフィール情報',
    uploadNewPicture: '新しい画像をアップロード',
    portfolioObjective: "ポートフォリオの目標",
    risk: 'リスク',
    investmentStrategies: '投資戦略',
    customizedInvestmentStrategies: 'カスタマイズされた投資戦略'
  },
  plan: {
    planList: '計画リスト',
    addPlan: '計画追加',
    planName: '計画名',
    enterPlanName: '計画名を入力してください',
    selectEmail: 'メールを選択',
    selectedEmail: '選択されたメールアドレス',
    emailHeader: 'メールヘッダー',
    enterEmailHeader: 'メールヘッダーを入力してください',
    emailFooter: 'メールフッター',
    enterEmailFooter: 'メールフッターを入力してください',
    emailContent: 'メール内容',
    selectEmailContent: 'メール内容を選択してください',
    selectReceiverGroup: '受信者グループを選択',
    selectedReceiverGroup: '選択された受信者グループ',
    nameRequired: '計画名を入力してください',
    emailRequired: 'メールアドレスを選択してください',
    contentRequired: 'メール内容を選択してください',
    receiverRequired: '受信者グループを選択してください',
    scheduleRequired: '送信スケジュールを設定してください',
    sendEmail: '送信メール',
    planTime: 'スケジュール',
    details: '詳細',
    scheduleTime: '配信予定時間',
    daily: '毎日',
    weekly: '毎週',
    monthly: '毎月',
    time: '時間',
    selectWeekday: '曜日を選択',
    selectDate: '日付を選択',
    monday: '月曜日',
    tuesday: '火曜日',
    wednesday: '水曜日',
    thursday: '木曜日',
    friday: '金曜日',
    saturday: '土曜日',
    sunday: '日曜日',
    dayOfMonth: '{{day}}日',
    emailConfig: {
      title: 'メール設定',
      protocol: 'プロトコル選択',
      emailAddress: 'メールアドレス',
      emailAddressPlaceholder: 'メールアドレスを入力してください',
      password: 'パスワード',
      passwordPlaceholder: 'パスワードを入力してください',
      mailServer: 'メールサーバー',
      mailServerPlaceholder: '例: imap.gmail.com',
      port: 'メールサーバーポート',
      portPlaceholder: '例: 993',
      cancel: 'キャンセル',
      confirm: '確認'
    },
    receiverGroup: {
      configTitle: '新規受信者グループの設定',
      groupName: 'グループ名',
      groupNamePlaceholder: 'グループ名を入力してください',
      selectReceiver: '受信者を選択',
      selectReceiverPlaceholder: '受信者を選択してください',
      description: 'グループ説明',
      descriptionPlaceholder: 'グループの説明を入力してください',
      nameRequired: 'グループ名を入力してください',
      selectRequired: '少なくとも1人の受信者を選択してください'
    },
    configureNewEmail: '新規メール設定',
    edit: '編集',
    cancel: 'キャンセル',
    confirm: '確認',
    emailUpdateSuccess: 'メール設定が正常に更新されました！',
    emailCreateSuccess: '新しいメール設定が正常に作成されました！',
    unknownError: '不明なエラーが発生しました。もう一度お試しください。',
    receiver_group: {
      select_title: '受信者グループの選択',
      config_new: '新規受信者グループの設定',
      cancel: 'キャンセル',
      confirm: '確認',
      create_success: '新しい受信者グループが正常に作成されました！',
      create_error: '受信者グループの作成に失敗しました！'
    },
    searchProducts: '製品を検索',
    sortBy: '並び替え',
    featured: '注目',
    popular: '人気',
    new: '新着',
    showing: '表示',
    filters: {
      allproducts: '全製品',
      uikit: 'UIキット',
      illustration: 'イラスト',
      wireframekit: 'ワイヤーフレームキット',
      icons: 'アイコン'
    },
    lifetime: 'ライフタイム',
    reset: 'リセット',
    apply: '適用',
    editContent: 'コンテンツを編集',
    enterEmailTitle: 'メールタイトルを入力してください',
    save: '保存',
    sendEmailNow: 'メールを送信',
    confirmSendEmail: 'メールを送信してもよろしいですか？',
    sendSuccess: '送信成功',
    sendFailed: '送信失敗',
    updateSuccess: '更新成功',
    updateFailed: '更新失敗',
    client: '顧客',
    sendTime: '送信時間',
    emailTitle: 'メールタイトル',
    sendStatus: '送信状態',
    sent: '送信済み',
    unsent: '未送信',
    operations: '操作',
    table: {
      user: 'ユーザー',
      sendTime: '送信時間',
      emailTitle: 'メールタイトル',
      emailContent: 'メール内容',
      status: 'ステータス',
      action: 'アクション'
    }
  },
  user: {
    noSimilarCustomers: '類似顧客なし',
    similarity: '類似度',
    similarInvestmentCustomers: '類似投資口座',
    aiAnalysis: 'AI分析、数分かかる場合があります',
    customerComparison: '顧客比較'
  },
  error: {
    compareClientFailed: '顧客比較に失敗しました'
  }
}

export default translation