import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure,  } from "@nextui-org/react";
import React, { useEffect, useImperativeHandle, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'

type ModalProps = {
  title: string
  content: string
  className?: string
  children?: React.ReactNode
  onSubmit: any, ref?: React.Ref<any>
  hiddenCancelButton?: boolean
}

export default forwardRef(function App({ title, content, children, className, onSubmit, hiddenCancelButton }: ModalProps, ref?: React.Ref<any>) {
  const { t } = useTranslation()
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const localRef = useRef();
  useImperativeHandle(ref, () => ({
    onOpen
  }))
  return (
    <>
      <div className={className || ''} onClick={(e) => { e.stopPropagation(); onOpen()}}>
        {children || ''}
      </div>
      <Modal className="z-[900]" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
              <ModalBody>
                {content}
              </ModalBody>
              <ModalFooter>
                {!hiddenCancelButton && <Button color="danger" variant="light" onPress={onClose}>
                  {t('common.cancel')}
                </Button>}
                <Button color="primary" onPress={() => { onSubmit(); onClose(); }}>
                  {t('common.save')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
})