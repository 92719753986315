const translation = {
  分析: '채팅',
  详情: '상세',
  复制成功: '복사 성공',
  所属经理: '매니저',
  投资策略: '투자 전략',
  目标: '목표',
  状态: '상태',
  建立日期: '생성일',
  最近分析: '최근 분석일',
  风险概况: '리스크 프로필',
  选择客户: '고객 선택',
  刚刚: '방금',
  save: '예',
  cancel: '취소',
  viewAll: '전체보기',
  moreLikeThis: '비슷한 항목 더보기',
  userSearch: {
    placeholder: '고객명으로 검색',
    search: '검색',
    sortBy: '정렬 기준',
    sortOrder: '정렬 순서',
    asc: '오름차순',
    desc: '내림차순',
    reset: '초기화',
    apply: '적용',
    isProfit: '수익 여부',
    all: '전체',
    low: '낮음',
    medium: '중간',
    high: '높음',
    riskTolerance: '위험 감내도',
  },
  chat: {
    思考中: '생각 중',
    思考秒: '{{number}}초 동안 생각 중',
    推荐问: '\n추천 질문:',
    消息异常: '메시지 오류',
    conversations: '대화',
    newConversation: '새 대화',
    deleteTip: '삭제 팁',
    confirmDeletion: '삭제하시겠습니까?',
    exportToPdf: 'PDF로 내보내기',
    sendToEmail: '이메일로 보내기',
    exportTip: '내보내기 팁',
    contentTooLong: "내용이 너무 깁니다. 수동으로 복사해 주세요.",
    expandSession: '세션 목록 확장',
  },
  index: {
    title: '고객 손익 비율',
    亏损客户数: '손실 계좌 수',
    盈利客户数: '수익 계좌 수',
    全部: '전체',
    亏损: '손실',
    盈利: '수익',
  },
  menus: {
    首页: '홈',
    文档: '데이터',
    客户: '계좌',
    指导: '사용자 가이드',
    定价: '구독',
    计划: '계획',
  },
  login: {
    wealthManager: '자산 관리자',
    individual: '개인 투자자',
    选择角色: '역할을 선택해 주세요. 당신은',
    genieTitle: '자산 관리자 코파일럿',
    登录描述: '금융 어시스턴트에 오신 것을 환영합니다 - 저희와 함께 지능형 금융을 경험하세요! 꽥!',
    请输入用户名: '사용자 이름을 입력하세요',
    请输入邮箱: '이메일을 입력하세요',
    获取验证码: '인증번호 받기',
    请输入邮箱验证码: '이메일 인증번호를 입력하세요',
    请确认密码: '비밀번호를 확인하세요',
    FirstName: '이름',
    LastName: '성',
    请输入手机号: '전화번호를 입력하세요',
    输入邮箱: '이메일 입력',
    输入密码: '비밀번호 입력',
    请输入密码: '비밀번호를 입력하세요',
    账号密码不正确: '이메일 또는 비밀번호가 올바르지 않습니다',
    注册账号: '계정 등록',
    登录: '로그인',
    注册: '등록',
    不能为空: '필수 항목',
    密码规则: "비밀번호는 8자 이상이며 숫자, 문자, 특수문자를 포함해야 합니다",
    两次输入的密码不一致: "입력한 두 비밀번호가 일치하지 않습니다",
    邮箱格式不正确: "잘못된 이메일 형식",
    注册失败: "등록 실패",
    注册成功: "등록 성공",
    发送验证码失败: "인증번호 전송 중 오류가 발생했습니다. 나중에 다시 시도해 주세요",
    注册失败1: "사용자 이름은 3자 이상이어야 합니다",
    注册失败2: "이미 존재하는 이메일입니다",
    注册失败3: "이미 존재하는 사용자 이름입니다",
    注册失败4: "이미 존재하는 전화번호입니다",
    注册失败5: "전화번호는 숫자여야 합니다",
    注册失败6: "잘못된 인증번호",
    logout: '로그아웃',
    个人信息: '프로필 수정',
    公司: '회사',
    邮箱: '이메일',
  },
  userDetailTable: {
    title: '포트폴리오 거래 내역',
    downTip: '거래 내역 다운로드',
    投资类别: '자산 클래스',
    特定投资或证券的名称: '이름',
    公开交易证券的股票代码或符号: '심볼',
    客户持有的股份或单位数量: '보유 수량',
    最初购买时的价格: '매수 비용',
    当前市场价格: '현재 가격',
    总价值: '시장 가치',
    原始成本: '원가',
    市场价值与成本基础之间的差额: '미실현 손익',
    年收益率: '연간 수익률',
    投资收入: '수익',
    总资产分配给此资产的比例: '자산 배분',
    风险水平: '위험 수준',
    更新投资组合数据的日期: '매수일',
  },
  upload: {
    文件类型不支持: '지원되지 않는 파일 형식',
    文件太大: '파일이 너무 큽니다. {{size}}MB를 초과할 수 없습니다',
    上传成功: '업로드 성공',
    上传限制个数: '일괄 업로드 제한 {{number}}개 파일',
    拖拽文件至此或者: '파일을 여기로 드래그하거나',
    下载模板: '템플릿 다운로드',
    下载用户数据: 'Genie에서 고객 계정 데이터 다운로드',
    下载理财数据: 'Genie에서 포트폴리오 거래 내역 데이터 다운로드',
    上传用户或理财信息: '계정 또는 자산 데이터 업로드',
    上传理财信息: '재무 정보 업로드',
    选择文件: '파일 선택',
    支持文件: '지원 {{exp}}, 각 파일은 {{size}}MB를 초과할 수 없습니다',
    importAccountData: 'Genie에 계정 데이터 가져오기',
    importPortfolioTransaction: 'Genie에 고객 계정의 포트폴리오 거래 가져오기',
    importTemplate: 'Genie에 고객 계정 데이터 가져오기',
    importPortfolioTemplate: 'Genie에 포트폴리오 거래 내역 데이터 가져오기',
  },
  api: {
    success: '성공',
    actionSuccess: '작업 성공',
    saved: '저장됨',
    create: '생성됨',
    remove: '제거됨',
    deleFail: '삭제 실패',
  },
  price: {
    free: '무료',
    title: 'Genie',
    subtitle: 'AI 기반 자산 관리자 코파일럿',
    perMonth: '월',
    includes: '포함',
    plusPlan: '플러스 플랜',
    mostPopular: '가장 인기',
    proPlan: '프로 플랜',
    paymentMethods: 'Stripe 및 PayPal',
    description: {
      free: "시작하는 개인 투자자를 위한 완벽한 선택으로, 스마트한 투자 여정을 시작하는 데 도움이 되는 기본적인 포트폴리오 분석 및 관리 기능을 제공합니다.",
      plus: "고급 투자자를 위해 설계되었으며, 더 나은 투자 수익을 달성하는 데 도움이 되는 심층적인 투자 분석과 다양한 전략 추천을 제공합니다.",
      pro: "전문 투자자를 위해 구축되었으며, 고급 분석 도구와 맞춤형 자문 서비스를 포함한 종합적인 투자 관리 솔루션을 제공합니다."
    },
    features: {
      analysis: '포트폴리오 분석 및 보고서',
      optimization: '맞춤형 투자\n최적화 및\n추천',
      trends: '시장 동향 인사이트',
      decisions: '투명한 의사결정',
      strategy: '목표 지향적 투자 전략',
      monitoring: '포트폴리오 성과 모니터링',
      simulation: '시나리오 시뮬레이션',
      tax: '세금 효율성 최적화',
      portfolios2: '최대 2개 포트폴리오',
      portfolios10: '최대 10개 포트폴리오',
      portfolios50: '최대 50개 포트폴리오',
      trial: '신규 사용자 1개월 무료 체험',
      freePlan: '+ 무료 플랜의 모든 기능'
    },
    extraCredits: {
      title: '추가 크레딧',
      description: '모든 등급에서 이용 가능',
      price: '$20',
      unit: '10,000 크레딧당'
    },
    extraStorage: {
      title: '추가 지식 저장소',
      description: '모든 등급에서 이용 가능',
      price: '$100',
      unit: 'GB당'
    }
  },
  overview: {
    name: '고객명',
    title: '개요',
    thisWeek: '이번 주',
    thisMonth: '이번 달',
    thisYear: '올해',
    totalAccounts: '전체 계좌',
    totalAssets: '전체 계좌 자산',
    accounts: '내 계좌 대시보드',
    account: '계좌',
    expenseCategory: '포트폴리오 배분',
    risk: '위험',
    balance: '잔액',
    unrealizedProfits: '미실현 이익',
    objective: '목표',
    investmentStrategies: '투자 전략',
    actions: '작업',
    portfolioTrend: '포트폴리오 추세',
    balanceTrend: '잔액 추세',
  },
  settings: {
    title: '설정',
    Basics: '기본',
    profileInformation: '프로필 정보',
    uploadNewPicture: '새 사진 업로드',
    portfolioObjective: "포트폴리오 목표",
    risk: '위험',
    investmentStrategies: '투자 전략',
    customizedInvestmentStrategies: '맞춤형 투자 전략'
  },
  plan: {
    planList: '계획 목록',
    addPlan: '계획 추가',
    planName: '계획 이름',
    sendEmail: '발신 이메일',
    planTime: '일정',
    details: '상세',
    enterPlanName: '계획 이름을 입력하세요',
    selectEmail: '이메일 선택',
    selectedEmail: '선택된 이메일',
    emailHeader: '이메일 헤더',
    enterEmailHeader: '이메일 헤더를 입력하세요',
    emailFooter: '이메일 푸터',
    enterEmailFooter: '이메일 푸터를 입력하세요',
    emailContent: '이메일 내용',
    selectEmailContent: '이메일 내용을 선택하세요',
    selectReceiverGroup: '수신자 그룹 선택',
    selectedReceiverGroup: '선택된 수신자 그룹',
    nameRequired: '계획 이름을 입력하세요',
    emailRequired: '이메일을 선택하세요',
    contentRequired: '이메일 내용을 선택하세요',
    receiverRequired: '수신자 그룹을 선택하세요',
    scheduleRequired: '발송 일정을 설정하세요',
    scheduleTime: '예약 발송 시간',
    daily: '매일',
    weekly: '매주',
    monthly: '매월',
    time: '시간',
    selectWeekday: '요일 선택',
    selectDate: '날짜 선택',
    monday: '월요일',
    tuesday: '화요일',
    wednesday: '수요일',
    thursday: '목요일',
    friday: '금요일',
    saturday: '토요일',
    sunday: '일요일',
    dayOfMonth: '{{day}}일',
    emailConfig: {
      title: '이메일 설정',
      protocol: '프로토콜 선택',
      emailAddress: '이메일 주소',
      emailAddressPlaceholder: '이메일 주소를 입력하세요',
      password: '비밀번호',
      passwordPlaceholder: '비밀번호를 입력하세요',
      mailServer: '메일 서버',
      mailServerPlaceholder: '예: imap.gmail.com',
      port: '메일 서버 포트',
      portPlaceholder: '예: 993',
      cancel: '취소',
      confirm: '확인'
    },
    receiverGroup: {
      configTitle: '새 수신자 그룹 구성',
      groupName: '그룹명',
      groupNamePlaceholder: '그룹명을 입력하세요',
      selectReceiver: '수신자 선택',
      selectReceiverPlaceholder: '수신자를 선택하세요',
      description: '그룹 설명',
      descriptionPlaceholder: '그룹 설명을 입력하세요',
      nameRequired: '그룹명을 입력해주세요',
      selectRequired: '최소 한 명의 수신자를 선택해주세요'
    },
    configureNewEmail: '새 이메일 구성',
    edit: '편집',
    cancel: '취소',
    confirm: '확인',
    emailUpdateSuccess: '이메일 구성이 성공적으로 업데이트되었습니다!',
    emailCreateSuccess: '새 이메일 구성이 성공적으로 생성되었습니다!',
    unknownError: '알 수 없는 오류가 발생했습니다. 다시 시도해주세요.',
    receiver_group: {
      select_title: '수신자 그룹 선택',
      config_new: '새 수신자 그룹 구성',
      cancel: '취소',
      confirm: '확인',
      create_success: '새 수신자 그룹이 성공적으로 생성되었습니다!',
      create_error: '수신자 그룹 생성에 실패했습니다!'
    },
    searchProducts: '제품 검색',
    sortBy: '정렬 기준',
    featured: '추천',
    popular: '인기',
    new: '신규',
    showing: '표시',
    filters: {
      allproducts: '모든 제품',
      uikit: 'UI 키트',
      illustration: '일러스트레이션',
      wireframekit: '와이어프레임 키트',
      icons: '아이콘'
    },
    lifetime: '수명',
    reset: '초기화',
    apply: '적용',
    editContent: '내용 편집',
    enterEmailTitle: '이메일 제목을 입력하세요',
    save: '저장',
    sendEmailNow: '이메일 보내기',
    confirmSendEmail: '이메일을 지금 보내시겠습니까?',
    sendSuccess: '전송 성공',
    sendFailed: '전송 실패',
    updateSuccess: '업데이트 성공',
    updateFailed: '업데이트 실패',
    client: '고객',
    sendTime: '발송 시간',
    emailTitle: '이메일 제목',
    sendStatus: '발송 상태',
    sent: '발송됨',
    unsent: '미발송',
    operations: '작업',
    table: {
      user: '사용자',
      sendTime: '전송 시간',
      emailTitle: '이메일 제목',
      emailContent: '이메일 내용',
      status: '상태',
      action: '작업'
    }
  },
  user: {
    noSimilarCustomers: '유사 고객 없음',
    similarity: '유사도',
    similarInvestmentCustomers: '유사 투자 계좌',
    aiAnalysis: 'AI 분석, 몇 분 정도 걸릴 수 있습니다',
    customerComparison: '계좌 비교'
  },
  error: {
    compareClientFailed: '고객 비교 실패'
  }
}

export default translation