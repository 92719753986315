const translation = {
  分析: '分析',
  详情: '詳情',
  复制成功: '複製成功',
  所属经理: '所屬經理',
  投资策略: '投資策略',
  目标: '目標',
  状态: '狀態',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '風險概況',
  选择客户: '選擇客戶',
  刚刚: '剛剛',
  save: '確定',
  cancel: '取消',
  viewAll: '查看全部',
  moreLikeThis: '更多類似產品',
  userSearch: {
    placeholder: '搜索客戶名稱',
    search: '搜索',
    sortBy: '排序方式',
    sortOrder: '排序順序',
    asc: '升序',
    desc: '降序',
    reset: '重置',
    apply: '應用',
    isProfit: '是否盈利',
    all: '全部',
    low: '低',
    medium: '中',
    high: '高',
    riskTolerance: '風險承受能力',
  },
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推薦問:',
    消息异常: '消息異常',
    conversations: '會話列表',
    newConversation: '新建會話',
    deleteTip: '刪除提示',
    confirmDeletion: '確認刪除？',
    exportToPdf: '導出為pdf',
    sendToEmail: '發送至郵箱',
    exportTip: '導出提示',
    contentTooLong: "內容過長，請手動複製內容。 ",
    expandSession: '展開會話列表',
  },
  index: {
    title: '資產變化',
    亏损客户数: '虧損賬戶',
    盈利客户数: '盈利賬戶',
    全部: '全部',
    亏损: '虧損',
    盈利: '盈利',
  },
  menus: {
    首页: '首頁',
    文档: '文檔',
    客户: '客戶',
    指导: '指導',
    定价: '定價',
    计划: '計劃',
  },
  login: {
    wealthManager: '理財經理',
    individual: '個人投資者',
    选择角色: '請選擇一個角色，你是',
    genieTitle: '您的財富管理助手',
    登录描述: '歡迎使用我們的理財助手-與我們一起開啟您的財富管理之旅，體驗智能理財的魅力！',
    请输入用户名: '請輸入用戶名',
    请输入邮箱: '請輸入郵箱',
    获取验证码: '獲取驗證碼',
    请输入邮箱验证码: '請輸入郵箱驗證碼',
    请确认密码: '請確認密碼',
    FirstName: '性',
    LastName: '名',
    请输入手机号: '請輸入手機號',
    输入邮箱: '輸入您的郵箱',
    输入密码: '輸入您的密碼',
    请输入密码: '請輸入密碼',
    账号密码不正确: '郵箱或密碼不正確',
    注册账号: '註冊帳號',
    登录: '登錄',
    注册: '註冊',
    不能为空: 'Required',
    密码规则: '密碼必須包含至少8個字符，包含數字、字母和特殊字符',
    两次输入的密码不一致: '兩次輸入的密碼不一致',
    邮箱格式不正确: '郵箱格式不正確',
    注册失败: '註冊失敗',
    注册成功: "註冊成功",
    发送验证码失败: '發送驗證碼時出現錯誤，請稍後再試',
    注册失败1: "用戶名至少要 3 個字符長",
    注册失败2: "郵箱已存在",
    注册失败3: "用戶名已存在",
    注册失败4: "電話號碼已存在",
    注册失败5: "電話號碼必須是數字",
    注册失败6: "驗證碼錯誤",
    logout: '退出',
    个人信息: '編輯個人信息',
    公司: '公司',
    邮箱: '郵箱',
  },
  userDetailTable: {
    title: '投資組合交易歷史',
    downTip: '下載交易歷史',
    投资类别: '投資類別',
    特定投资或证券的名称: '投資名稱',
    公开交易证券的股票代码或符号: '代碼',
    客户持有的股份或单位数量: '持有數量',
    最初购买时的价格: '購買價格',
    当前市场价格: '當前市場價格',
    总价值: '總價值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '未實現利潤',
    年收益率: '年收益率',
    投资收入: '投資收入',
    总资产分配给此资产的比例: '資產比例',
    风险水平: '風險水平',
    更新投资组合数据的日期: '購買日期',
  },
  upload: {
    文件类型不支持: '文件類型不支持',
    文件太大: '文件過大，不能超過 {{size}}MB',
    上传成功: '上傳成功',
    上传限制个数: '批量上傳限制 {{number}} 個文件',
    拖拽文件至此或者: '拖拽文件到此，或',
    下载模板: '下載模板',
    下载用户数据: '從Genie下載客戶帳戶數據',
    下载理财数据: '從Genie下載投資組合交易歷史數據',
    上传用户或理财信息: '上傳帳戶或財富數據',
    上传理财信息: '上傳理財信息',
    选择文件: '選擇文件',
    支持文件: '支持 {{exp}}，每個文件不能超過 {{size}}MB',
    importAccountData: '導入客戶帳戶數據到Genie',
    importPortfolioTransaction: '導入投資組合交易歷史數據到Genie',
    importTemplate: '導入客戶帳戶數據到Genie',
    importPortfolioTemplate: '導入投資組合交易歷史數據到Genie',
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已創建',
    remove: '已移除',
    deleFail: '刪除失敗',
  },
  price: {
    free: '免費',
    title: 'Genie',
    subtitle: 'AI驅動的財富管理助手',
    perMonth: '每月',
    includes: '包含',
    plusPlan: 'Plus套餐',
    mostPopular: '最受欢迎',
    proPlan: 'Pro套餐',
    paymentMethods: 'Stripe和PayPal支付',
    "description": {
      "free": "適合個人投資者入門使用，提供基示的投資組合分析和管理功能，幫助您開啟智能理財之旅。",
      "plus": "面向進階投資者，提供更深入的投資分析和多樣化的投資策略建議，助您實現更優的投資收益。",
      "pro": "為專業投資者打造，提供全方位的投資管理解決方案，包含高級分析工具和個性化投資顧問服務。"
    },
    features: {
      analysis: '投資組合分析和報告',
      optimization: '個性化投資\n優化和\n建議',
      trends: '市場趨勢洞察',
      decisions: '透明的決策過程',
      strategy: '目標導向的投資策略',
      monitoring: '投資組合績效監控',
      simulation: '情景模擬',
      tax: '稅收效率優化',
      portfolios2: '最多2個投資組合',
      portfolios10: '最多10個投資組合',
      portfolios50: '最多50個投資組合',
      trial: '新用戶1個月免費試用',
      freePlan: '+ 所有免費版功能'
    },
    extraCredits: {
      title: '額外積分',
      description: '適用於所有等級',
      price: '￥140',
      unit: '每10,000積分'
    },
    extraStorage: {
      title: '額外知識存儲',
      description: '適用於所有等級',
      price: '￥700',
      unit: '每GB'
    }
  },
  overview: {
    name: '客戶名稱',
    title: '概覽',
    thisWeek: '本周',
    thisMonth: '本月',
    thisYear: '本年',
    totalAccounts: '總帳戶數',
    totalAssets: '總帳戶資產',
    accounts: '我的账户概览',
    account: '帳戶',
    expenseCategory: '投資組合配置',
    risk: '風險',
    balance: '餘額',
    unrealizedProfits: '未實現收益',
    objective: '目標',
    investmentStrategies: '投資策略',
    actions: '操作',
    portfolioTrend: '投資組合趨勢',
    balanceTrend: '餘額趨勢',
  },
  settings: {
    title: '設置',
    Basics: '基礎設置',
    profileInformation: '個人信息',
    uploadNewPicture: '上傳新照片',
    portfolioObjective: "投資組合目標",
    risk: '風險',
    investmentStrategies: '投資策略',
    customizedInvestmentStrategies: '定制投資策略'
  },
  plan: {
    planList: '計劃列表',
    addPlan: '新增計劃',
    planName: '計劃名稱',
    sendEmail: '發送郵箱',
    planTime: '計劃時間',
    details: '詳情',
    enterPlanName: '請輸入計劃名稱',
    selectEmail: '選擇郵箱',
    selectedEmail: '已選擇郵箱',
    emailHeader: '郵件開頭',
    enterEmailHeader: '請輸入郵件開頭',
    emailFooter: '郵件結尾',
    enterEmailFooter: '請輸入郵件結尾',
    emailContent: '郵件內容',
    selectEmailContent: '請選擇郵件內容',
    selectReceiverGroup: '選擇接收者組',
    selectedReceiverGroup: '已選擇接收者組',
    nameRequired: '請輸入計劃名稱',
    emailRequired: '請選擇郵箱',
    contentRequired: '請選擇郵件內容',
    receiverRequired: '請選擇接收者組',
    scheduleRequired: '請設置發送時間',
    scheduleTime: '計劃發送時間',
    daily: '每天',
    weekly: '每週',
    monthly: '每月',
    time: '時間',
    selectWeekday: '選擇星期',
    selectDate: '選擇日期',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
    dayOfMonth: '{{day}}日',
    emailConfig: {
      title: '配置郵箱',
      protocol: '選擇協議',
      emailAddress: '電子郵件地址',
      emailAddressPlaceholder: '請輸入郵箱地址',
      password: '密碼',
      passwordPlaceholder: '請輸入密碼',
      mailServer: '郵件伺服器',
      mailServerPlaceholder: '例如: imap.gmail.com',
      port: '郵件伺服器端口號',
      portPlaceholder: '例如: 993',
      cancel: '取消',
      confirm: '確定'
    },
    receiverGroup: {
      configTitle: '配置新接收者組',
      groupName: '分組名稱',
      groupNamePlaceholder: '請輸入分組名稱',
      selectReceiver: '選擇接收者',
      selectReceiverPlaceholder: '選擇接收者',
      description: '分組描述',
      descriptionPlaceholder: '請輸入分組描述',
      nameRequired: '請輸入分組名稱',
      selectRequired: '請至少選擇一個接收者'
    },
    configureNewEmail: '配置新郵箱',
    edit: '編輯',
    cancel: '取消',
    confirm: '確定',
    emailUpdateSuccess: '郵箱配置已成功更新！',
    emailCreateSuccess: '新郵箱配置已成功創建！',
    unknownError: '發生未知錯誤，請重試。',
    receiver_group: {
      select_title: '選擇接收者組',
      config_new: '配置新接收者組',
      cancel: '取消',
      confirm: '確定',
      create_success: '新接收者組已成功創建！',
      create_error: '創建接收者組失敗！'
    },
    searchProducts: '搜尋產品',
    sortBy: '排序方式',
    featured: '精選',
    popular: '熱門',
    new: '最新',
    showing: '顯示',
    filters: {
      allproducts: '所有產品',
      uikit: 'UI 套件',
      illustration: '插畫',
      wireframekit: '線框套件',
      icons: '圖示'
    },
    lifetime: '生命週期',
    reset: '重置',
    apply: '套用',
    editContent: '編輯內容',
    enterEmailTitle: '請輸入郵件標題',
    save: '保存',
    sendEmailNow: '立即發送郵件',
    confirmSendEmail: '確定立即發送郵件嗎？',
    sendSuccess: '發送成功',
    sendFailed: '發送失敗',
    updateSuccess: '更新成功',
    updateFailed: '更新失敗',
    client: '客戶',
    sendTime: '發送時間',
    emailTitle: '郵件標題',
    sendStatus: '發送狀態',
    sent: '已發送',
    unsent: '未發送',
    operations: '操作',
    table: {
      user: '用戶',
      sendTime: '發送時間',
      emailTitle: '郵件標題',
      emailContent: '郵件內容',
      status: '狀態',
      action: '操作'
    }
  },
  user: {
    noSimilarCustomers: '暫無相似客戶',
    similarity: '相似度',
    similarInvestmentCustomers: '相似客戶',
    aiAnalysis: 'AI分析，可能需要幾分鐘',
    customerComparison: '客戶比較'
  },
  error: {
    compareClientFailed: '客戶比較失敗'
  }
}

export default translation
