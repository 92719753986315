'use client'

import AuthProvider from '@/components/auth-provider'
import { NextUIProvider } from '@nextui-org/react'
import {ThemeProvider as NextThemesProvider} from "next-themes";
import I18nProvider from './i18n'
import { getLocaleOnClient } from '@/lib/i18n'
import { MenuContextProvider } from '@/context/menuContext'
import {ToastProvider} from '@/components/toast'
import { SessionMenuContextProvider } from '@/context/sessionMenuContext'
import { ModalProvider } from '@/context/ModalContext'
import { ScreenContextProvider } from '@/context/screenContext'
import { PopupProvider } from '@/context/PopupContext'
import React, { useEffect } from 'react';

export function Providers({ children }: { children: React.ReactNode }) {
  const [locale, setLocale] = React.useState('')
  
  useEffect(() => {
    setLocale(getLocaleOnClient())
  }, [])


  return (
    <AuthProvider>
      <I18nProvider locale={locale}>
        <NextUIProvider>
          <NextThemesProvider attribute="class" defaultTheme="light">
            <ToastProvider>
              <ScreenContextProvider>
                <MenuContextProvider>
                  <SessionMenuContextProvider>
                    <PopupProvider>
                      <ModalProvider>
                        {children}
                      </ModalProvider>
                    </PopupProvider>
                  </SessionMenuContextProvider>
                </MenuContextProvider>
              </ScreenContextProvider>
            </ToastProvider>
          </NextThemesProvider>
        </NextUIProvider>
      </I18nProvider>
    </AuthProvider>
  )
}